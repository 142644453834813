<template>
	<div class="about">
		<div class="container my-5">
			<div class="row my-5">
				<h1 class="mt-5">About AlphaBatem Labs</h1>
			</div>
			<div class="row my-5">
				<div class="col-12">
					<p>AlphaBatem is a metaverse technology company focused on providing next-generation digital infrastructure. We facilitate the growth of the Metaverse & ensure
						frictionless cohesion between integrated games and virtual worlds, using a standards based, open platform.</p>
					<p>Our suite of developer toolkits, SDK's & supporting infrastructure can supercharge both new & existing Metaverse games, experiences, and virtual worlds and vastly
						speed up their route to market.</p>
				</div>
			</div>
			<div class="row my-5">
				<div class="col-12">
					<h2 class="mb-5">Our Vision</h2>
					<h5 class="mb-3 fw-bold">AlphaBatem’s mission is to be the easiest tool for metaverse creation</h5>

					<p>We're an educational resource, designed to help new users become familiar with the metaverse and its key concepts. We want to:</p>

					<ul>
						<li>Explain metaverse to anyone new to the technology</li>
						<li>Help new users get started with the metaverse</li>
						<li>Provide seamless user experience with the metaverse</li>
						<li>Provides users and businesses with a complete toolkit to create their Metaverse, and monetise this effectively</li>
						<li>Help new developers to start building</li>
						<li>Cover updates in the metaverse world</li>
						<li>Showcase resources created by the community</li>
						<li>Bring metaverse education to as many languages as possible</li>
					</ul>
				</div>
			</div>
			<div class="row my-5">
				<div class="col-12">
					<h2 class="mb-5">Core principles</h2>

					<h4 class="mb-4 fw-bold">1. AlphaBatem is a portal to the Metaverse 🌏</h4>

					<p>We want our users to have their interest piqued and their questions answered. So our portal needs to combine information, "magic moments" and links to the brilliant
						community resources that exist out there. The purpose of our content is to be an “onboarding portal” and not a substitute for the extensive resources that already
						exist. We're keen to support and integrate with community built resources, giving them more visibility and making them more discoverable.</p>

					<p>AlphaBatem's community is at the heart of this: we need to not just serve the community, but work with them and incorporate their feedback. The website isn't just for
						the community we have now but for the community we hope to grow into. We must remember our community is global, containing people from many languages, regions, and
						cultures.</p>
				</div>
			</div>
			<div class="row my-5">
				<div class="col-12">
					<h4 class="mb-4 fw-bold">2. AlphaBatem is always evolving 🛠</h4>

					<p>We want our users to have their interest piqued and their questions answered. So our portal needs to combine information, "magic moments" and links to the brilliant
						community resources that exist out there. The purpose of our content is to be an “onboarding portal” and not a substitute for the extensive resources that already
						exist. We're keen to support and integrate with community built resources, giving them more visibility and making them more discoverable.</p>

					<p>AlphaBatem's community is at the heart of this: we need to not just serve the community, but work with them and incorporate their feedback. The website isn't just for
						the community we have now but for the community we hope to grow into. We must remember our community is global, containing people from many languages, regions, and
						cultures.</p>
				</div>
			</div>
			<div class="row my-5">
				<div class="col-12">
					<h4 class="mb-4 fw-bold">3. AlphaBatem is not a typical product website </h4>

					<p>AlphaBatem is a big thing: it includes a community, a technology, a set of ideas and ideologies, and more. This means the website needs to handle many different user
						journeys, from “a developer who wants a specific tool” and “a newcomer who just bought some NFTs and doesn’t know what a metaverse is"</p>

					<p>"What is the best website for a Metaverse platform?" remains an open question - we are pioneers. Building this requires experimentation.</p>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";

export default {
	name: "Team",
	components: {Footer},
	data() {
		return {
			team: [
				{name: "Scott Hague", title: "CEO, AlphaBatem Labs", twitter: "", linkedin: "", image: "/images/team_photos/Scott_Hague.png"},
				{name: "John McLeod", title: "COO, AlphaBatem Labs", twitter: "", linkedin: "", image: "/images/team_photos/John_Mcleod.png"},
				{name: "Nicholas Harrison", title: "CMO, AlphaBatem Labs", twitter: "", linkedin: "", image: "/images/team_photos/Nick_Harrison.png"},
				{name: "Francis So", title: "CFO, AlphaBatem Labs", twitter: "", linkedin: "", image: "/images/team_photos/Francis_so.png"},
				{name: "Travis C.", title: "Art Director", twitter: "", linkedin: "", image: "/images/team_photos/Travis_C.png"},
				{name: "Robert Jones", title: "2D Artist & Graphic Designer", twitter: "", linkedin: "", image: "/images/team_photos/Robert_Jones.png"},
				{name: "Amman Istwal", title: "Community Manager", twitter: "", linkedin: "", image: "/images/team_photos/Amman_Istwal.png"},
				{name: "Ehigie Akala", title: "Social Media Manager", twitter: "", linkedin: "", image: "/images/team_photos/Ehigie_Akala.png"},
				{name: "Lois Nwanze", title: "Lead UX/UI Designer", twitter: "", linkedin: "", image: "/images/team_photos/Lois_Nwanze.png"},
				{name: "Temitope Olatunji", title: "Python Developer", twitter: "", linkedin: "", image: "/images/team_photos/Temitope_Olatunji.png"}
			]
		}
	}
}
</script>

<style scoped>
ul {
	color: white;
	font-weight: normal;
}
</style>